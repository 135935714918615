import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { use100vh } from "react-div-100vh";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Slideshow
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Icons
import { HorizontalLogo } from "../components/icons/horizontal-logo";
import { VerticalLogo } from "../components/icons/vertical-logo";

const SplashContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1000;

  cursor: pointer;

  & .fill {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 20;

    background-color: #2466cb;
    mix-blend-mode: multiply;

    transition: 500ms opacity ease;
    opacity: ${props => props.fillOpacity};
  }

  & .horizontal-logo-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 30;

    transition: 500ms opacity ease;
    opacity: ${props => props.horizontalLogoOpacity};

    & svg {
      width: calc(100vw - 300px);
      max-width: 1100px;

      height: auto;

      @media (max-width: 768px) {
        width: calc(100vw - 40px);
      }
    }
  }

  & .vertical-logo-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    z-index: 10;
    background-color: #fff;
    width: 68px;

    transition: 500ms opacity ease;
    opacity: ${props => props.verticalLogoOpacity};

    @media (max-width: 768px) {
      top: unset;
      left: 0;

      width: 100%;
      height: 55px;

      line-height: 60px;
      text-align: center;
    }
  }

  & .slideshow-container {
    height: ${props => props.height}px;

    & .slideshow-image-container {
      height: ${props => props.height}px;
    }

    & .landscape-image {
      & img {
        object-fit: cover;
      }
    }

    & .portrait-image {
      padding: 30px;

      & img {
        object-fit: contain;
      }
    }

    & img {
      width: 100%;
      height: 100%;
    }
  }
`;

const SlideContainer = styled.div`
  background-color: ${props => props.bgColor};
`;

const SplashPage = ({ data }) => {
  const height = use100vh();

  const [verticalLogoOpacity, setVerticalLogoOpacity] = useState(0);
  const [horizontalLogoOpacity, setHorizontalLogoOpacity] = useState(1);
  const [fillOpacity, setFillOpacity] = useState(1);
  const [isAutoplay, setIsAutoplay] = useState(false);

  // Splash
  const [hideSplash, setHideSplash] = useState(false);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 769px)");

  useEffect(() => {
    const fadeOutLogoAndFill = setTimeout(() => {
      setHorizontalLogoOpacity(0);
      setFillOpacity(0);

      const fadeInVerticalLogo = setTimeout(() => {
        setVerticalLogoOpacity(1);
        setIsAutoplay(true);
      }, 1000);
      return () => clearTimeout(fadeInVerticalLogo);
    }, 3000);
    return () => clearTimeout(fadeOutLogoAndFill);
  }, []);

  const desktopSlideshow = data.prismicSplash.data.body.map(
    (content, index) => {
      if (content.slice_type === `landscape_image`) {
        return (
          <SlideContainer
            className="slideshow-image-container landscape-image"
            key={`desktop_slideshow_slide_${index}_landscape`}
          >
            {content.primary.image.fluid !== null && (
              <img
                srcSet={content.primary.image.fluid.srcSetWebp}
                src={content.primary.image.fluid.srcWebp}
                alt={content.primary.image.alt}
              />
            )}
          </SlideContainer>
        );
      }

      if (content.slice_type === `portrait_image`) {
        return (
          <SlideContainer
            key={`desktop_slideshow_slide_${index}_portrait`}
            className="slideshow-image-container portrait-image"
            bgColor={content.primary.background_color}
          >
            {content.primary.image.fluid !== null && (
              <img
                srcSet={content.primary.image.fluid.srcSetWebp}
                src={content.primary.image.fluid.srcWebp}
                alt={content.primary.image.alt}
              />
            )}
          </SlideContainer>
        );
      }
    }
  );

  const mobileSlideshow = data.prismicSplash.data.body1.map(
    (content, index) => {
      if (content.slice_type === `landscape_image`) {
        return (
          <SlideContainer
            className="slideshow-image-container landscape-image"
            key={`mobile_slideshow_slide_${index}_landscape`}
          >
            {content.primary.image.fluid !== null && (
              <img
                srcSet={content.primary.image.fluid.srcSetWebp}
                src={content.primary.image.fluid.srcWebp}
                alt={content.primary.image.alt}
              />
            )}
          </SlideContainer>
        );
      }

      if (content.slice_type === `portrait_image`) {
        return (
          <SlideContainer
            className="slideshow-image-container portrait-image"
            bgColor={content.primary.background_color}
            key={`mobile_slideshow_slide_${index}_portrait`}
          >
            {content.primary.image.fluid !== null && (
              <img
                srcSet={content.primary.image.fluid.srcSetWebp}
                src={content.primary.image.fluid.srcWebp}
                alt={content.primary.image.alt}
              />
            )}
          </SlideContainer>
        );
      }
    }
  );

  return (
    <SplashContainer
      height={height}
      verticalLogoOpacity={verticalLogoOpacity}
      horizontalLogoOpacity={horizontalLogoOpacity}
      fillOpacity={fillOpacity}
    >
      <div className="fill" />

      <div className="horizontal-logo-container">
        <HorizontalLogo fill={`#FF8513`} />
      </div>

      <div className="slideshow-container">
        {isDesktop ? (
          <Fade
            arrows={false}
            transitionDuration={0}
            duration={5000}
            pauseOnHover={false}
            autoplay={isAutoplay}
            infinite={true}
            canSwipe={true}
          >
            {desktopSlideshow}
          </Fade>
        ) : (
          <Fade
            arrows={false}
            transitionDuration={0}
            duration={5000}
            pauseOnHover={false}
            autoplay={isAutoplay}
            infinite={true}
            canSwipe={true}
          >
            {mobileSlideshow}
          </Fade>
        )}
      </div>

      <div className="vertical-logo-container">
        {isDesktop ? (
          <VerticalLogo fill={`#4383EF`} />
        ) : (
          <HorizontalLogo fill={`#4383EF`} />
        )}
      </div>
    </SplashContainer>
  );
};

export default withPreview(SplashPage);

export const query = graphql`
  {
    prismicSplash {
      data {
        body {
          ... on PrismicSplashBodyPortraitImage {
            id
            slice_type
            primary {
              background_color
              image {
                alt
                dimensions {
                  height
                  width
                }
                fluid(srcSetBreakpoints: [1400, 1600, 1800, 2400, 3600]) {
                  srcWebp
                  srcSetWebp
                  aspectRatio
                }
              }
            }
          }
          ... on PrismicSplashBodyLandscapeImage {
            id
            slice_type
            primary {
              image {
                alt
                dimensions {
                  height
                  width
                }
                fluid(srcSetBreakpoints: [1400, 1600, 1800, 2400, 3600]) {
                  srcWebp
                  srcSetWebp
                  aspectRatio
                }
              }
            }
          }
        }
        body1 {
          ... on PrismicSplashBody1PortraitImage {
            id
            slice_type
            primary {
              background_color
              image {
                alt
                dimensions {
                  height
                  width
                }
                fluid {
                  srcWebp
                  srcSetWebp
                  aspectRatio
                }
              }
            }
          }
          ... on PrismicSplashBody1LandscapeImage {
            id
            slice_type
            primary {
              image {
                alt
                dimensions {
                  height
                  width
                }
                fluid {
                  srcWebp
                  srcSetWebp
                  aspectRatio
                }
              }
            }
          }
        }
      }
    }
  }
`;
